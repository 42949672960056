<p-dialog header="Confirm Unlocking Content"
        [(visible)]="displayPublicWindowEndDateDialog"
        [modal]="true"
        [minY]="70"
        [baseZIndex]="10000"
        styleClass="dialog-max-40em"
        [style]="{'min-width': '200px'}"
        [appendTo]="'body'">
   
    <div >
            <h5>Select Public Window End Date and Time:</h5>
            <p-calendar [(ngModel)]="publicWindowEndDate" [showTime]="true" [inline]="true" [showIcon]="true" dateFormat="@"></p-calendar>
    </div>
        <p-footer>
            <button type="button"  pButton
                    *ngIf="publicWindowEndDate"
                    icon="pi pi-check"
                    (click)="batchUpdatePublicWindowEndDateContainers()"
                    label="Yes">
            </button>
            <button type="button" pButton icon="fa fa-close" (click)="displayPublicWindowEndDateDialog=false" label="No" class="p-button-secondary" data-element-id="app-content-components-container-workflow-status-button-no"></button>
        </p-footer>
</p-dialog>

<div class="card card-w-title top-level-card">
    <h1>Batch Edit Series Episodes</h1>

    <p-menubar [model]="actionMenuItems"></p-menubar>

<div *ngIf="!processing">

<p-treeTable [value]="data" [columns]="cols" selectionMode="checkbox" [(selection)]="selectedContainers"
(onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)">
    <ng-template pTemplate="caption">

    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                <p-treeTableCheckbox [value]="rowNode" *ngIf="i == 0" [disabled] = isSelectionDisabled(rowNode,rowData)></p-treeTableCheckbox>
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
</p-treeTable>
</div>


<div *ngIf="processing" style="margin:0.5em;">
    <p-progressBar [value]="progress"></p-progressBar>
</div>
</div>




<!--
<div class="card card-w-title top-level-card">
<p-treeTable [value]="data" [columns]="cols" [paginator]="true" [rows]="10" [totalRecords]="1000" [loading]="loading" (onNodeExpand)="onNodeExpand($event)">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
</p-treeTable>
</div>
-->
