<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;" data-element-id="app-settings-forms-profile-form-cancel"></button>
            </div>
            <div class="ui-g-10">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;" data-element-id="app-settings-forms-profile-form-save"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['name']" name="name" placeholder="Required" required data-element-id="app-settings-forms-profile-form-name">
                </div>
            </div>

        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['export']" *ngxPermissionsOnly="['object.write.all', 'object.write.profile']"></trigger-pipeline-modal-form>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="type">Type</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="entityType.name" name="type" data-element-id="app-settings-forms-profile-form-type">
                </div>
                <div class="ui-g-8">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid" data-element-id="app-settings-forms-profile-form-guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Profile Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="origin">Bundle Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.bundle_id" name="bundle" placeholder="Required" required data-element-id="app-settings-forms-profile-form-bundle-id">
                </div>
                <div class="ui-g-3">
                    <label for="type">Profile Type</label>
                    <select [(ngModel)]="container.data.profile_type" name="profiletype" class="form-control" data-element-id="app-settings-forms-profile-form-profile-type">
                        <option *ngFor="let profileType of profileTypes" [value]="profileType">{{ profileType }}</option>
                    </select>
                </div>    
                <div class="ui-g-3">
                    <label for="type">Payload Type</label>
                    <select [(ngModel)]="container.data.payload_type" name="payloadtype" class="form-control" data-element-id="app-settings-forms-profile-form-profile-payload-type">
                        <option *ngFor="let payloadType of payloadTypes" [value]="payloadType">{{ payloadType }}</option>
                    </select>
                </div>    
            </div>
        </p-panel>

        <hr>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Payload</span>
            </p-header>
            <json-editor #payload *ngIf="container.data.payload_type == 'application/json'" [options]="editorOptionsJson" [data]="payloadObj" data-element-id="app-settings-forms-profile-form-profile-payload-editor"></json-editor>
            <textarea *ngIf="container.data.payload_type != 'application/json'" [rows]="20" class="ui-g-12" pInputTextarea name="payload" [(ngModel)]="container.data.payload" autoResize="autoResize"></textarea>
        </p-panel>
    </div>
</form>
